<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="form-bg col-10">
        <div class="form row">
          <h1 class="title">Zarejestruj się</h1>
          <h2>Dane <span>personalne</span></h2>
          <div
            class="form-control-wrap col-12 col-md-6"
            v-for="(value, index) of form.personal"
            :key="index"
          >
            <label
              v-if="index != 'patient_pesel'"
              class="label-item"
              :for="value.label"
              >{{ form.personal[index].label }}</label
            >
            <label
              :for="value.label"
              v-else
              class="label-item"
              :class="{
                'd-none':
                  form.personal['patient_document_type'].value !== 'pesel',
              }"
              >{{ form.personal[index].label }}</label
            >

            <select
              class="form-select"
              v-if="form.personal[index].type === 'select'"
              v-model="form.personal[index].value"
            >
              <option
                v-for="(option, index) of form.personal[index].options"
                :value="option.value"
                :key="index"
              >
                {{ option.label }}
              </option>
            </select>

            <input
              v-else-if="index === 'patient_pesel'"
              :type="form.personal[index].type"
              v-model="form.personal[index].value"
              :class="{
                'd-none':
                  form.personal['patient_document_type'].value !== 'pesel',
                'is-valid': validation.valid[index],
                'is-invalid': validation.invalid[index],
              }"
              v-on:focus="clearValidation(index)"
            />
            <input
                v-else-if="index === 'patient_birthdate'"
                :placeholder="form.personal[index].placeholder"
                :type="form.personal[index].type"
                :id="index"
                :min="form.personal[index].min"
                :max="form.personal[index].max"
                :class="{
                'is-valid': validation.valid[index],
                'is-invalid': validation.invalid[index],
              }"
                v-on:focus="clearValidation(index)"
                v-model="b_date"
            />
            <input
              v-else
              :type="form.personal[index].type"
              :id="index"
              :min="form.personal[index].min"
              :max="form.personal[index].max"
              :class="{
                'is-valid': validation.valid[index],
                'is-invalid': validation.invalid[index],
              }"
              v-on:focus="clearValidation(index)"
              v-model="form.personal[index].value"
            />

            <div
              class="invalid-feedback"
              v-if="validation.invalid[index] && index !== 'patient_pesel'"
            >
              {{ validation.invalid[index] }}
            </div>

            <div
              v-else-if="
                index === 'patient_pesel' &&
                form.personal['patient_document_type'].value == 'pesel'
              "
              class="invalid-feedback"
              :class="{
                'is-valid': validation.valid[index],
                'is-invalid': validation.invalid[index],
              }"
            >
              {{ validation.invalid.patient_pesel }}
            </div>

            <div
              v-else-if="
                index === 'patient_pesel' &&
                form.personal['patient_document_type'].value == 'document'
              "
              class="invalid-feedback"
              :class="{
                'd-none':
                  form.personal['patient_document_type'].value !== 'pesel',
              }"
            >
              {{ validation.valid.patient_pesel }}
            </div>
          </div>

          <h2>Dane <span> kontaktowe</span></h2>
          <div
            class="form-control-wrap col-12 col-md-6"
            v-for="(value, index) of form.contact"
            :key="index"
          >
            <label :for="value.label">{{ form.contact[index].label }}</label>
            <input
              :type="form.contact[index].type"
              :id="index"
              :class="{
                'is-valid': validation.valid[index],
                'is-invalid': validation.invalid[index],
              }"
              v-on:focus="clearValidation(index)"
              v-model="form.contact[index].value"
            />
            <div class="invalid-feedback" v-if="validation.invalid[index]">
              {{ validation.invalid[index] }}
            </div>
          </div>
          <div
            class="invalid-checkbox-feedback"
            v-if="validation.invalid.checkbox"
          >
            {{ validation.invalid.checkbox }}
          </div>
          <div class="form-group">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="agreement"
                v-on:focus="clearValidation('checkbox')"
              />
              <label class="form-check-label text-justify" for="agreement"
                >Oświadczam, że wszystkie wyżej podane dane są zgodne ze stanem
                faktycznym. Jestem świadom(a) odpowiedzialności karnej za
                złożenie fałszywego oświadczenia.</label
              >
            </div>
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="agreement2"
                v-on:focus="clearValidation('checkbox')"
              />
              <label class="form-check-label text-justify" for="agreement2"
                >Oświadczam, że ukończyłem/am 16 rok życia, zapoznałem się z
                <a
                  target="_blank"
                  href="https:/geneme.eu/docs/ioz.pdf"
                  style="font-weight: 600"
                  >informacjami dotyczącymi zlecenia oraz dotyczącymi
                  przetwarzania danych osobowych</a
                >
                i zgadzam się na przetwarzenie moich danych przez GeneMe sp. z
                o.o. w celu realizacji usług badań laboratoryjnych.</label
              >
            </div>
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="agreement3"
                v-on:focus="clearValidation('checkbox')"
              />
              <label class="form-check-label text-justify" for="agreement3"
                >Zgadzam się na przeprowadzenie czynności
                diagnostycznych.</label
              >
            </div>
          </div>
          <div
            v-if="message.status != null"
            class="message d-flex justify-content-center align-items-center flex-column"
          >
            <div v-if="message.status == 200" class="message-wrap">
              <i
                class="fas fa-times-circle close-button"
                @click="this.message.status = null"
              ></i>

              <img class="icon" alt="geneme logo" src="../assets/success.png" />
              <div class="message-content">
                Rejestracja przebiegła pomyślnie.
              </div>
              <button class="btn btn-main" @click="this.message.status = null">
                ZAMKNIJ
              </button>
            </div>

            <div v-else class="message-wrap">
              <i
                class="fas fa-times-circle close-button"
                @click="message.status = null"
              ></i>

              <img class="icon" alt="geneme logo" src="../assets/error.png" />
              <div class="message-content">
                Wystąpił błąd podczas wysyłania formularza. Proszę sprawdzić
                poprawność danych.
              </div>
              <button class="btn btn-main" @click="message.status = null">Ok</button>
            </div>
          </div>

          <div class="btn-wrap text-center my-4">
            <button @click="register_patient" class="btn btn-main">
              zarejestruj się
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-6 col-md-3 footer-item">
          Geneme Sp. z o.o.<br />
          e-mail: <a href="mailto:kontakt@geneme.eu">kontakt@geneme.eu</a><br />
          tel.: <a href="tel:576-272-000"> 576-272-000 </a><br />
          dostępność: 7:00-22:00 pn-nd<br />
          ul. Kampinoska 25<br />
          80-180 Gdańsk<br />
        </div>
        <div class="col-6 col-md-4 text-center">
          <img class="logo" alt="geneme logo" src="../assets/logo.png" />
        </div>
        <div class="col-12 col-md-3 footer-item">
          <a href="https://geneme.eu/docs/pp.pdf?v2" target="_blank"
            >Polityka Prywatności</a
          >
          <br />
          <a href="https://geneme.eu/docs/od.pdf?v2" target="_blank"
            >Ochrona danych osobowych</a
          ><br />
          © 2020 Geneme sp. z o.o.<br />
          All Rights Reserved<br />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios';
import { validatePolish } from 'validate-polish';
import countries_array from '@/data/countries.json';

export default {
  components: {},
  data: function () {
    return {
      message: {
        status: null
      },

      saved: false,
      loading: false,
      errors: {
        pesel: null,
      },
      b_date:'',
      isValid: false,
      form: {
        personal: {
          patient_document_type: {
            value: 'pesel',
            type: 'select',
            label: 'Oświadczam, że:',

            options: {
              0: {
                value: 'pesel',
                label: 'Posiadam numer pesel',
              },
              1: {
                value: 'document',
                label: 'Nie posiadam numeru pesel',
              },
            },
          },
          patient_citizenship: {
            value: 'PL',
            type: 'select',
            label: 'Obywatelstwo',
            options: countries_array.map(c => ({
              value: c.Code,
              label: c.Name,
            })),
          },
          patient_firstname: {
            value: '',
            type: 'text',
            label: 'Imię',
          },
          patient_surname: {
            value: '',
            type: 'text',
            label: 'Nazwisko',
          },
          patient_birthdate: {
            value: '',
            type: 'text',
            min: '1900-01-01',
            max: new Date(),
            label: 'Data urodzenia',
            name: 'dateBirth',
            placeholder: "YYYY-MM-DD"
          },
          patient_sex: {
            value: 'k',
            type: 'select',
            label: 'Płeć',
            options: {
              0: {
                value: 'k',
                label: 'kobieta',
              },
              1: {
                value: 'm',
                label: 'mężczyzna',
              },
            },
          },
          patient_pesel: {
            label: 'Numer pesel',
            value: '',
            type: 'text',
          },
          patient_document_id: {
            label: 'Numer dokumentu tożsamości',
            value: '',
            type: 'text',
          },
          referring_physician: {
            value: '',
            type: "hidden"
          },
        },
        contact: {
          patient_phone: {
            label: 'Numer telefonu',
            value: '',
            type: 'text',
          },
          patient_postcode: {
            label: 'Kod pocztowy',
            value: '',
            type: 'text',
          },
          patient_city: {
            label: 'Miasto',
            value: '',
            type: 'text',
          },
          patient_street: {
            label: 'Ulica',
            value: '',
            type: 'text',
          },
          patient_house_number: {
            label: 'Numer domu',
            value: '',
            type: 'text',
          },
          patient_apartment_number: {
            label: 'Numer mieszkania',
            value: '',
            type: 'text',
          },
        },
      },
      validation: {
        invalid: {},
        valid: {},
      },
    };
  },
  props: {},
  computed: {
    formarray() {
      let temp = Object.entries(this.form.contact).map(x => ({ [x[0]]: x[1].value }));
      let temp2 = Object.entries(this.form.personal).map(x => ({ [x[0]]: x[1].value }));
      return Object.assign({}, ...temp, ...temp2);
    },
  },
  methods: {
    attemptSubmit(t) {
      let d = new Date();
      if (t === 'y') {
        return d.getFullYear();
      } else if (t === 'm') {
        return ('0' + (Number(d.getMonth()) + 1)).slice(-2);
      } else if (t === 'd') {
        return ('0' + d.getDate()).slice(-2);
      } else if (t === 'h') {
        return ('0' + d.getHours()).slice(-2);
      } else if (t === 'i') {
        return ('0' + d.getMinutes()).slice(-2);
      }
    },
    validate_pesel(v) {
      if(this.form.personal.patient_document_type.value === 'pesel') {
        if (!this.form.personal.patient_pesel.value) {
          this.validation.invalid.patient_pesel = `Proszę uzupełnić pole 'Numer Pesel'`;
        } else {
          if (!validatePolish.pesel(v)) {
            this.validation.invalid.patient_pesel = 'Niepoprawny numer pesel';
          } else {
            this.validation.valid.patient_pesel = 'Ok';
          }
        }
      }else{
        this.validation.valid.patient_pesel = 'Ok';
      }
    },
    validate_Id(v) {
      if(this.form.personal.patient_document_type.value === 'document' || (this.form.personal.patient_document_type.value === 'pesel' && v!='')){
        if (!this.form.personal.patient_document_id.value) {
          this.validation.invalid.patient_document_id = `Proszę uzupełnić pole 'Numer Dokumentu Tożsamości'`;
        } else {
          if (
              validatePolish.identityCard(v) ||
              this.form.personal.patient_document_id.value.match(/[a-z]{2}[0-9]{7}/)
          ) {
            this.validation.valid.patient_document_id = 'Ok';
          } else {
            this.validation.invalid.patient_document_id = 'Nieprawidłowy format';
          }
        }
      }else{
        this.validation.valid.patient_document_id = 'Ok';
      }
    },

    validateString(category, name, title) {
      if (!this.form[category][name].value) {
        this.validation.invalid[name] = `Proszę uzupełnić pole '${title}'`;
      } else if (this.form[category][name].value.length < 2) {
        this.validation.invalid[
          name
        ] = `${title} powinno zawierać conajmniej dwa znaki`;
      } else if (
        !this.form[category][name].value.match(
          /^([a-zA-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+(?:. |-| |'))*[a-zA-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]*\s*$/
        )
      ) {
        this.validation.invalid[
          name
        ] = `${title} nie może zawierać cyfr oraz znaków specjalnych`;
      } else {
        this.validation.valid[name] = 'Ok';
      }
    },
    validateStringwithnumbers(category, name, title) {
      if (!this.form[category][name].value) {
        this.validation.invalid[name] = `Proszę uzupełnić pole '${title}'`;
      } else if (this.form[category][name].value.length < 2) {
        this.validation.invalid[
            name
            ] = `${title} powinno zawierać conajmniej dwa znaki`;
      }else {
        this.validation.valid[name] = 'Ok';
      }
    },
    validateAddressNumber(category, name, title) {
      if (name == 'patient_house_number' && !this.form[category][name].value) {
        this.validation.invalid[name] = `Proszę uzupełnić pole '${title}'`;
      } else if (
        !this.form[category][name].value.match(
          /^[1-9]\d*(?:[-\s]?[a-zA-Z0-9]+)?$/
        )
      ) {
        if (this.form[category][name].value) {
          this.validation.invalid[
            name
          ] = `'${title}' nie może zaczynać się od litery i zawierać znaków specjalnych`;
        } else {
          this.validation.valid[name] = 'Ok';
        }
      } else {
        this.validation.valid[name] = 'Ok';
      }
    },
    validateBirthDate() {
      this.form.personal.patient_birthdate.value = this.b_date
      if (!this.form.personal.patient_birthdate.value) {
        this.validation.invalid.patient_birthdate = `Proszę uzupełnić pole 'Data Urodzenia'`;
      } else if (
        new Date(this.form.personal.patient_birthdate.value).getFullYear() <
          1900 ||
        new Date(this.form.personal.patient_birthdate.value) > new Date()
      ) {
        this.validation.invalid.patient_birthdate =
          'Proszę wprowadzić poprawną datę urodzenia';
      } else {
        this.validation.valid.patient_birthdate = 'Ok';
      }
    },
    validatePhone(category, name, title) {
      if (!this.form[category][name].value) {
        this.validation.invalid[name] = `Proszę uzupełnić pole '${title}'`;
      } else if (this.form[category][name].value.length < 9) {
        this.validation.invalid[
          name
        ] = ` ${title} powinien zawierać conajmniej 9 cyfr.`;
      } else if (this.form[category][name].value.length > 20) {
        this.validation.invalid[name] = `${title} jest za długi.`;
      } else if (
        !this.form[category][name].value.match(
          /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
        )
      ) {
        this.validation.invalid[name] = 'Nieprawidłowy format';
      } else {
        this.validation.valid[name] = 'Ok';
      }
    },
    validatePostcode(category, name, title) {
      if (!this.form[category][name].value) {
        this.validation.invalid[name] = `Proszę uzupełnić pole '${title}'`;
      } else if (
        !String(this.form[category][name].value).match(/^[0-9]{2}-[0-9]{3}$/)
      ) {
        this.validation.invalid[name] =
          'Proszę wprowadzić prawidłowy format (00-000)';
      } else {
        this.validation.valid[name] = 'Ok';
      }
    },
    validateCheckbox() {
      if (
        document.getElementById('agreement').checked &&
        document.getElementById('agreement2').checked &&
        document.getElementById('agreement3').checked
      ) {
        this.validation.valid.checkbox = 'Ok';
      } else {
        this.validation.invalid.checkbox = 'Proszę zaznaczyć wszystkie pola';
      }
    },
    validate: function () {
      this.validateString('personal', 'patient_firstname', 'Imię');
      this.validateString('personal', 'patient_surname', 'Nazwisko');
      this.validateString('contact', 'patient_city', 'Miasto');
      // ulica może być np. 1 maja 11 listopada itp. pomysł dobry, ale do zrobienia z walidacją, że nie może być numeru na końcu poprzedzonego spacją
      this.validateStringwithnumbers('contact', 'patient_street', 'Ulica');
      this.validateAddressNumber(
        'contact',
        'patient_house_number',
        'Numer domu'
      );
      this.validateAddressNumber(
        'contact',
        'patient_apartment_number',
        'Numer mieszkania'
      );
      this.validateBirthDate();

      if (this.form.personal.patient_citizenship.value == 'PL') {
        if (this.form.personal.patient_document_type.value == 'pesel') {
          this.validate_pesel(this.form.personal.patient_pesel.value);
        }
      } else {
        this.validation.valid.patient_pesel = 'Ok';
      }

      this.validate_Id(this.form.personal.patient_document_id.value);
      this.validatePhone('contact', 'patient_phone', 'Numer telefonu');
      this.validatePostcode('contact', 'patient_postcode', 'Kod pocztowy');
      this.validateCheckbox();

      this.$forceUpdate();
    },
    clearValidation: function (field) {
      this.validation.valid[field] = '';
      this.validation.invalid[field] = '';
      this.$forceUpdate();
    },

    register_patient() {
      this.validate();
      const dataToObj = JSON.parse(JSON.stringify(this.validation.invalid));
      let isInvalidMessageEmpty = Object.entries(dataToObj).every(value => {
        return value[1] == ''
      });
      if (isInvalidMessageEmpty) {
        axios.post('https://admin.geneme.eu/api/antygen/register',this.formarray)
        .then( (response) => {
          this.message = response
        })
        .catch( (error) => {
          this.message.status = {error: error.message}
        })
      }
    },
  },
  watch: {
    b_date: function(v) {
      let last = v.substr(-1)
      let temp = v
      if (!last.match('^[0-9]$') || v.length > 10) {

        temp = v.substr(0, v.length - 1)
      }
      else if([4,7].includes(temp.length)) {
        temp += '-'
      }
      this.b_date = temp;

    }
  },
};
</script>

<style>
body {
  overflow-x: hidden;
  font-family: 'Nunito', sans-serif;
}

body a {
  color: #29bdf3;
  text-decoration: none;
}

h1.title {
  text-align: center;
  font-family: 'Dosis', sans-serif;
  text-transform: uppercase;
  color: #333;
  margin: 5px auto 20px;
  font-size: 30px;
  font-weight: 600;
}

.message {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.message-wrap {
  height: 320px;
  width: 600px;
  padding: 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  position: relative;
}

.message-content {
  color: #000;
  font-size: 25px;
  line-height: 33px;
  font-weight: 500;
}

.close-button {
  text-align: center;
  cursor: pointer;
  color: #e41381;
  position: absolute;
  right: -5px;
  top: -29px;
  font-size: 40px;
  z-index: 999;
  margin: 20px auto;
  -webkit-transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  transform-origin: center;
  transform-style: preserve-3D;
}

.close-button:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

i.fa-check-circle,
.fa-exclamation-circle,
img.icon {
  color: rgba(185, 185, 185, 0.7);
  font-size: 80px;
  height: 80px;
  width: 80px;
  opacity: 0.3;
}

.form {
  margin-top: 100px !important;
  padding: 30px 40px;
  margin-left: 80px;
  background-color: rgba(248, 248, 248, 0.9) !important;
  border-radius: 10px;
  position: relative;
  font-family: 'Nunito', sans-serif;
}

.form::before {
  content: ' ';
  display: block;
  position: absolute;
  top: -50px;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-image: url('../assets/bg_img.jpg');
  background-size: cover;
  z-index: -99;
}

.form::after {
  content: ' ';
  display: block;
  position: absolute;
  top: 95px;
  left: 5%;
  right: 5%;
  height: 1px;
  background-color: rgb(212, 212, 212);
}

.form-bg {
  z-index: 999;
}

.form h2 {
  margin-top: 25px;
  margin-bottom: 10px;
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 25px;
  font-family: 'Dosis', sans-serif;
  font-weight: 600;
}

.form h2 span {
  color: #e41381;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-control-wrap {
  position: relative;
  z-index: 99;
  padding-bottom: 10px;
}

.form-control-wrap > * {
  margin-left: 10px;
}

.form-control-wrap label {
  display: inline-block;
  color: #333;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
}

.form-control-wrap input,
.form-control-wrap select {
  border: none;
  display: block;
  font-family: inherit;
  font-size: 14px;
  padding: 8px;
  width: 90%;
  text-align: left;
  font-weight: 600;
  border-radius: 4px;
  text-transform: uppercase;
}

.form-control-wrap input:focus {
  outline: 0;
  border: 2px solid #39bdee;
}

.form-control-wrap input.is-valid {
  border: 1px solid #198754;
}

.form-control-wrap input.is-invalid {
  border: 1px solid #dc3545;
}
.form-check .form-check-input[type='checkbox'] {
  border-radius: 0;
  cursor: pointer;
  border: 3px solid rgb(212, 212, 212);
  width: 1.1em;
  height: 1.1em;
  margin-top: 1px;
  border-radius: 3px;
}

.form-check .form-check-input[type='checkbox']:checked {
  background-color: #e41381;
}

.form-control-wrap input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.invalid-checkbox-feedback {
  padding-top: 20px;
  margin-left: 10px;
  margin-bottom: -20px;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 15px;
  color: #dc3545;
  font-weight: 600;
}
.invalid-feedback {
  font-size: 15px !important;
  color: #dc3545;
  font-weight: 600;
}
.form-group {
  padding-top: 30px;
  margin-bottom: 30px;
  margin-left: 10px;
  max-width: 95% !important;
}

.form-group .form-check label {
  font-weight: 600;
  font-size: 13px;
  color: #333;
  text-transform: uppercase;
  line-height: 16px;
  margin-bottom: 5px;
  font-family: 'Nunito', sans-serif;
}

.btn.btn-main {
  background-color: #39bdee;
  border-radius: 30px;
  padding: 15px 38px;
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Dosis', sans-serif;
}

.btn.btn-main:hover {
  background-color: #0bafeb;
  box-shadow: 1px 5px 18px 1px rgb(16 208 241 / 44%);
  color: #fff;
}

.footer {
  margin: 50px auto 20px;
  background-color: #fff;
  padding-top: 60px;
  font-family: 'Dosis', sans-serif;
  font-size: 14px;
}
footer a {
  color: #29bdf3;
  font-weight: 500;
  text-decoration: none;
}

footer img.logo {
  max-height: 70%;
}
.footer .row {
  max-width: 85%;
  margin: auto;
  justify-content: space-between;
}
.footer-item:last-child {
  text-align: right;
}
.footer a {
  text-decoration: none;
  font-family: 'Dosis', sans-serif;
}

@media only screen and (max-width: 1100px) {
  .form {
    padding: 5px 5px 5px 20px;
  }
  .form h2:first-of-type {
    padding-top: 20px;
  }
  h1.title {
    padding-top: 20px;
  }
  footer img.logo {
    max-height: 50%;
  }
}

@media only screen and (max-width: 770px) {
  footer img.logo {
    margin: 10px auto 0;
    max-height: 38%;
  }
}

@media only screen and (max-width: 630px) {
  h1.title {
    font-size: 26px;
  }
  .form h2 {
    font-size: 22px;
  }
  .form h2:first-of-type {
    padding-top: 10px;
  }
  .form::after {
    top: 80px;
  }
  .message-wrap {
    height: 320px;
    width: 90vw;
  }
  i.fa-check-circle,
  .fa-exclamation-circle,
  img.icon {
    font-size: 80px;
    height: 70px;
    width: 70px;
  }
  .message-content {
    font-size: 24px;
  }
  .form {
    padding: 5px 0 5px 10px;
  }
  .btn.btn-main {
    padding: 10px 30px;
  }
  .btn-wrap .btn.btn-main {
    margin-left: -15px;
  }
}

@media only screen and (max-width: 450px) {
  .form {
    padding: 0;
  }
  .message-content {
    font-size: 20px;
    line-height: 25px;
  }
  .footer .row {
    margin-left: 20px;
    margin-right: 4px;
  }
  footer img.logo {
    margin: 10px auto 0;
    max-height: 28%;
  }
  .footer-item:last-child {
    text-align: left;
  }
}
@media only screen and (max-width: 340px) {
  .footer {
    padding-top: 40px;
  }
  footer img.logo {
    margin: 10px auto 0;
    max-height: 23%;
  }
}

@media only screen and (max-width: 300px) {
  footer img.logo {
    max-height: 20%;
  }
}
</style>
