<template>
  <div class="text-center p-4" style="height: 160px">
    <img alt="geneme logo" src="./assets/logo.png" style="max-height: 100%" />
  </div>
  <!-- <div class="d-flex justify-content-center">
  <button @click="lang='en'" v-if="lang==='pl'" class="btn btn-info text-white text-uppercase">form in English</button>
    <button @click="lang='pl'" v-if="lang==='en'" class="btn btn-info text-white text-uppercase">formularz po Polsku</button>
  </div> -->
  <div v-if="lang==='pl'">
    <Register />
  </div>
  <div v-else>
    <Registeren />
  </div>

</template>

<script>
import Register from './components/Register.vue';
import Registeren from './components/RegisterEn.vue';

export default {
  name: 'App',
  components: {
    Register,
    Registeren,
  },
  data: function () {
    return {
      lang: 'pl'
    }
  }
};
</script>

<style></style>
